@import '../node_modules/modern-normalize/modern-normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  background: #fdfeff;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  width: 10px;
  border-radius: 5px;
}

a {
  text-decoration: none;
  color: #4c4;
}

a:visited {
  text-decoration: none;
  color: #4c4;
}

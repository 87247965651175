.sidebar {
  width: 100%;
  min-height: 300px;
  height: auto;
  margin-top: 50px;

  &__section {
    width: 100%;
    background-color: white;
    border: 1px solid #eeeeee;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    span { 
      font-weight: bold;
    }
  }

  textarea{
    width: 100%;
    height: 100px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
  }

  textarea:focus{
    outline:none;
  }
}

//accordion

.accordion {
  width: 100%;
  background: #f1f1f1;
  border-radius: 5px;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
  line-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  cursor: pointer;
}

.accordion-content {
  max-height: 0px;
  overflow: hidden;
  transition: 0.2s;
}

.accordion-content.active {
  max-height: 400px;
  transition: 1s;
}

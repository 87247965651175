//Login

.login-container {
  display: flex;
  width: 100vw;
  height: 100vh;

  &__content {
    width: 380px;
    height: 230px;
    border: 1px solid #eeeeee;
    background: white;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    -webkit-box-shadow: 2px 3px 14px -7px rgba(74, 74, 74, 1);
    -moz-box-shadow: 2px 3px 14px -7px rgba(74, 74, 74, 1);
    box-shadow: 2px 3px 14px -7px rgba(74, 74, 74, 1);
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }

  p {
    color: rgba(0, 0, 0, 0.654);
    width: 280px;
    padding-bottom: 15px;
  }
  h3 {
    margin: 0;
    position: absolute;
    margin-top: -150px;
  }

  &__logo {
    position: absolute;
    margin-top: -180px;
  }
}

//Home

.home-container {
  display: flex;
  width: 80%;
  min-height: 500px;
  height: auto;
  margin: 0 auto;
  margin-top: 100px;
  padding-top: 50px;
}

//Client Managers
.filter {
  width: 80%;
  min-height: 300px;
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  flex-wrap: wrap;

  &__left {
    width: 47%;
    min-height: 100%;
  }

  &__right {
    width: 50%;
    min-width: 200px;
    min-height: 100%;
  }
}

.projects {
  width: 80%;
  min-height: 300px;
  height: auto;
  margin: 0 auto;
  margin-top: 30px;

  &__sort {
    width: 320px;
    position: absolute;
    background: white;
    right: 70px;
  }

  &__top-row {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    width: 67%;
    height: auto;
    margin-top: 50px;
  }

  &__right {
    width: 30%;
    height: auto;
    margin-top: 50px;
  }

  &__sort-button {
    width: 130px;
    height: 30px;
    background: none;
    outline: 0;
    border: none;
    cursor: pointer;
    margin-top: 16px;

    &:active {
      outline: none;
    }

    &-icon {
      padding-top: 10px;
      height: 20px;
      width: 20px;
    }
  }
}

@media only screen and (max-width: 1320px) {
  .filter {
    width: 80%;
    flex-wrap: wrap;
    justify-content: space-between;

    &__left {
      width: 800px;
      min-height: 100%;
    }

    &__right {
      width: 800px;
      min-width: 200px;
      min-height: 100%;
    }
  }
}
